// UI

export const breakpoints = {
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1340px', // Узкое место - чат
  xxl: '1420px',
  xxxl: '1900px',
}

export const colors = {
  black: '#000000',
  grayDarkest: '#11283E',
  grayDarker: '#475C70',
  grayDarken: '#73879B',
  grayDark: '#90A4B7',
  gray: '#A1B5C8',
  grayLight: '#B7C6D4',
  grayLighten: '#D6DDE4',
  grayLighter: '#E7ECF1',
  grayLightest: '#F2F5F8',
  white: '#ffffff',

  blue: '#0F3E6D',
  blueLight: '#3D6389',
  blueLighten: '#2F94F9',

  azureDark: '#297977',
  azure: '#1DC3BF',
  azureLight: '#1BD6D1',

  greenDark: '#426646',
  green: '#37A3A0',

  purpleDark: '#5E4F7E',

  pink: '#F55279',

  redDark: '#9C4646',
  red: '#F55252',

  orange: '#FF912C',

  yellowDark: '#815E28',
}

export const theme = {
  colorPrimary: colors.azure,
  colorSecondary: colors.blue,
  colorDanger: colors.pink,

  textColor: colors.grayDarker,
  textColorMuted: colors.grayDark,

  bodyBg: colors.grayLightest,
  borderColor: colors.grayLighter,
  placeholderColor: colors.grayLightest,

  fontFamily: `'Cera Pro', -apple-system, BlinkMacSystemFont, 'Roboto', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  fontSize: '18px',
  lineHeight: '1.4',

  RTB: '1340px',

  containerMaxWidth: '1200px',
  containerGutters: '20px',
  containerGuttersMd: '30px',
  containerGuttersXxl: '40px',

  mobileHeaderHeight: '56px',
  desktopHeaderHeight: '100px',
  sidebarWidthOpened: '300px',
  sidebarWidthClosed: '90px',
  productsSearchHeightXl: '107px',
  productsSearchHeightXXXl: '87px',
  productsSearchIndent: '24px',

  transitionTime: '0.15s ease',
  transitionTimeSlow: '0.3s ease',
  transitionTimePage: '.1s',

  boxShadow: '0px 6px 10px rgba(15, 62, 109, 0.02)',
  borderRadius: '16px',
  opacity: '0.7',

  zIndexNProgress: 110,
  zIndexTooltip: 100,
  zIndexModal: 90,
  zIndexChat: 80,
  zIndexSidebar: 10,
  zIndexMobHeader: 5,
}

// Other
